export const filterTypes = {
  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',
  SET_FILTER_VALUE: 'SET_FILTER_VALUE'
}

export const dataTypes = {
  SET_DATA: 'SET_DATA',
  SET_METADATA: 'SET_METADATA',
  SET_CARD_DATA: 'SET_CARD_DATA'
}

export const loadingItemsTypes = {
  ADD_LOADING_ITEM: 'ADD_LOADING_ITEM',
  REMOVE_LOADING_ITEM: 'REMOVE_LOADING_ITEM',
  RESET_LOADING_ITEMS: 'RESET_LOADING_ITEMS'
}

export const renderDataTypes = {
  SET_FILTERED_CARDS: 'SET_FILTERED_CARDS',
  SET_FILTER_DESCRIPTION: 'SET_FILTER_DESCRIPTION',
  SET_FILTER_FORM_OPEN: 'SET_FILTER_FORM_OPEN',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SELECT_CARD: 'SELECT_CARD',
  DESELECT_CARD: 'DESELECT_CARD'
}
